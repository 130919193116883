import { contentApiLoadError } from '@store/alerts/alertConstants';
import { setAlert } from '@store/alerts/alertsState';
import {
  initCurriculaGoalLists,
  initCurriculaGoalListsFailed,
  initCurriculaRelations,
  initCurriculaRelationsFailed,
  initCurriculaRoots,
  initCurriculaRootsFailed,
  loadCurriculum,
  loadCurriculumFailed,
  loadDocument,
  loadDocumentFailed,
  loadGoalFailed,
  loadGoals,
  loadGoalsCorrelations,
  loadGoalsCorrelationsFailed,
  setCurriculaGoalLists,
  setCurriculaRelations,
  setCurriculaRoots,
  setCurriculum,
  setDocument,
  setGoal,
  setGoalsCorrelations,
} from '@store/contentApi/contentApiState';
import { setUrlParams } from '@store/curriculum/curriculumState';
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { logAndCaptureException } from '@utils/logAndCaptureException';
import {
  CONTENTAPI_DOCUMENT_KEYS,
  getCurriculaGoalLists,
  getCurriculaRelations,
  getCurriculum,
  getDocument,
  getGoal,
  getLlinkidGoalCorrelations,
  getMinimalCurricula,
} from './contentApiDataAccess';

function* doInitCurriculaRoots() {
  try {
    const result = yield call(getMinimalCurricula);
    yield put(setCurriculaRoots(result));
  } catch (ex) {
    logAndCaptureException(ex);
    yield put(initCurriculaRootsFailed(ex.message));
    yield put(setAlert(contentApiLoadError));
  }
}

function* doInitCurriculaGoalLists() {
  try {
    const length = yield select((state) => state.contentApiData.curriculaGoalLists.length);
    if (!length) {
      const result = yield call(getCurriculaGoalLists);
      yield put(setCurriculaGoalLists(result));
    }
  } catch (ex) {
    yield put(initCurriculaGoalListsFailed(ex.message));
    yield put(setAlert(contentApiLoadError));
  }
}

function* doInitCurriculaRelations() {
  try {
    const result = yield call(getCurriculaRelations);
    yield put(setCurriculaRelations(result));
  } catch (ex) {
    yield put(initCurriculaRelationsFailed(ex.message));
    yield put(setAlert(contentApiLoadError));
  }
}

function* loadDocumentSaga(action) {
  const { key, refresh } = action.payload;
  try {
    const result = yield call(getDocument, key, refresh);
    yield put(setDocument({ key, document: result }));
  } catch (ex) {
    yield put(loadDocumentFailed({ key, message: ex.message }));
    yield put(setAlert(contentApiLoadError));
  }
}

function* loadCurriculumIntroduction(curriculum, refresh) {
  const allPrograms = yield select((state) => state.studyProgrammeApiData.allPrograms);

  if (!allPrograms?.length) return;
  if (curriculum.applicability?.studyProgrammes?.length) {
    const [studyProgram] = curriculum.applicability.studyProgrammes;
    const fullStudyProgram = allPrograms.find((e) => e.$$meta.permalink === studyProgram.href);
    const grade = fullStudyProgram.$$grade;

    if (grade) {
      const gradeKey = CONTENTAPI_DOCUMENT_KEYS.INTRODUCTION_GRADE[grade];
      yield put(loadDocument({ key: gradeKey, refresh }));
    }
  }
}

function* loadCurriculumView(action) {
  const { curriculumKey, refresh } = action.payload;
  try {
    const { isLoaded, isFailed } = yield select(
      (state) => state.contentApiData.curriculaToLoad[curriculumKey]
    );
    if ((!isLoaded && !isFailed) || refresh) {
      const curriculum = yield getCurriculum(curriculumKey, refresh);
      yield put(setCurriculum({ curriculumKey, curriculum }));
      yield call(loadCurriculumIntroduction, curriculum, refresh);
    }
  } catch (ex) {
    console.error(ex);
    yield put(loadCurriculumFailed({ curriculumKey }));
    yield put(setAlert(contentApiLoadError));
  }
}

function* loadGoalView(key, refresh) {
  try {
    const { isLoaded, isFailed } = yield select((state) => state.contentApiData.goalsToLoad[key]);
    if ((!isLoaded && !isFailed) || refresh) {
      const goal = yield call(getGoal, key, refresh);
      yield put(setGoal({ key, goal }));
    }
  } catch (ex) {
    yield put(loadGoalFailed({ key }));
  }
}

function* loadLlinkidGoalCorrelationSaga() {
  try {
    const curriculaCorrelation = yield call(getLlinkidGoalCorrelations);
    yield put(setGoalsCorrelations({ curriculaCorrelation }));
  } catch (ex) {
    yield put(loadGoalsCorrelationsFailed());
  }
}

function* loadGoalViews(action) {
  const { keys, refresh } = action.payload;
  yield all(keys.map((e) => fork(loadGoalView, e, refresh)));
}

export function* watchInitContentSaga() {
  yield takeLatest(initCurriculaRoots, doInitCurriculaRoots);
  yield takeLeading([initCurriculaGoalLists, setUrlParams], doInitCurriculaGoalLists);
  yield takeLatest(initCurriculaRelations, doInitCurriculaRelations);
}

export function* watchCurriculumSaga() {
  yield takeEvery(loadCurriculum, loadCurriculumView);
  yield takeEvery(loadDocument, loadDocumentSaga);
  yield takeEvery(loadGoals, loadGoalViews);
  yield takeEvery(loadGoalsCorrelations, loadLlinkidGoalCorrelationSaga);
}
