import { isBefore } from 'date-fns';
import { isAfter } from 'date-fns/isAfter';
import { isSameDay } from 'date-fns/isSameDay';
import { addWeeks } from 'date-fns/addWeeks';
import { subWeeks } from 'date-fns/subWeeks';
import { addDays } from 'date-fns/addDays';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { last } from 'lodash-es';

import commonUtils from '@kathondvla/sri-client/common-utils';
import { endOfDayUTC } from '@utils/dateHelpers';
import { utc } from '@date-fns/utc';

export const getActivitiesForPlan = (plan, allActivities) => {
  let activities = [];
  let safety = 0;
  let parentHrefs = new Set([plan.$$meta.permalink]);
  do {
    // eslint-disable-next-line no-loop-func
    const children = allActivities.filter((a) => parentHrefs.has(a.parent.href));
    if (children.length > 0) {
      activities = activities.concat(children);
    }
    parentHrefs = new Set(children.map((e) => e.$$meta.permalink));
    safety += 1;
  } while (safety < 10 && parentHrefs.size > 0);

  return activities;
};

export const fillExpandedClasses = (plan, classes) => {
  const currentClass = classes?.find((c) => c.$$meta.permalink === plan.class.href) || {
    $$meta: { permalink: '00000000-0000-0000-0000-000000000000' },
    $$displayName: 'klas (verwijderd)',
    key: last(plan.class.href.split('/')),
    endDate: new Date().toISOString(),
  };

  return {
    ...plan,
    class: currentClass,
  };
};

export const sortByClassName = (a, b) => {
  return a.class.$$displayName > b.class.$$displayName ? 1 : -1;
};

export const sortByDisplayName = (a, b) => {
  return a.$$displayName > b.$$displayName ? 1 : -1;
};

export const sortByPeriod = (a, b) => {
  if (a.period.startDate < b.period.endDate) return -1;
  if (a.period.startDate > b.period.endDate) return 1;
  return 0;
};

export const groupActivitiesByParent = (activities) => {
  const parents = activities?.filter((a) => !a.parent.href.includes('/activities/'));

  const grouped = parents?.sort(sortByPeriod).map((parent) => {
    const children = activities
      .filter(
        (a) => a.parent.href === `/llinkid/activityplanning/activityplans/activities/${parent.key}`
      )
      .sort(sortByPeriod);

    return {
      ...parent,
      children,
    };
  });

  return grouped;
};

export const filterActivitiesByRange = (activities, { start, end }) => {
  const parents = activities?.filter((a) => !a.parent.href.includes('/activities/'));
  const children = activities?.filter((a) => a.parent.href.includes('/activities/'));

  const filteredChildren = children.filter((a) => {
    return (
      (isSameDay(new Date(a.period.startDate), new Date(start)) ||
        isAfter(new Date(a.period.startDate), new Date(start))) &&
      (isSameDay(new Date(a.period.startDate), new Date(end)) ||
        isBefore(new Date(a.period.startDate), new Date(end)))
    );
  });

  const includedParents = new Set(filteredChildren.map((c) => c.parent.href));
  const filtered = [
    ...parents.filter((p) => includedParents.has(p.$$meta.permalink)),
    ...filteredChildren,
  ];

  return filtered;
};

export const fillExpandedCreators = (plan, teachers) => {
  const creators = plan?.creators?.map((creator) => {
    const currentCreator = teachers?.find((t) => t.$$meta.permalink === creator.href);
    return {
      ...creator,
      $$displayName: currentCreator?.$$displayName,
    };
  });

  return {
    ...plan,
    creators,
  };
};

export function filterValidCalendarCreationCurriculum(leerplanList, me) {
  if (leerplanList.length === 0) return leerplanList;
  const filteredList = leerplanList
    .filter((item) => item.valid === true)
    .filter((item) => item.okan || item.type !== 'PLAN')
    .filter((item) => (item.type === 'TEACHER' ? item.creator === me.$$meta.permalink : true)); // you can only select your own creations for teacher-level.

  return filteredList;
}

export const formatDateOptions = (date) => ({
  value: date.toISOString(),
  name: format(date, 'dd/MM'),
});

export const addWeekAt = (position, weeks) => {
  let lastPeriod = null;
  let weeksBefore = [];
  let weeksAfter = [];
  const emptyWeek = {
    key: commonUtils.generateUUID(),
    description: null,
    period: {
      startDate: null,
      endDate: null,
    },
    goals: [],
    attachments: [],
  };

  if (position === 0) {
    lastPeriod = weeks[0].period;
    weeksAfter = weeks;
  } else if (position === weeks.length) {
    lastPeriod = weeks[weeks.length - 1].period;
    weeksBefore = weeks;
  } else {
    lastPeriod = weeks[position - 1].period;
    weeks.forEach((w, index) => {
      if (index < position) weeksBefore.push(w);
      else weeksAfter.push(w);
    });
  }

  const startDate =
    position === 0
      ? new Date(lastPeriod.startDate)
      : addWeeks(parseISO(lastPeriod.startDate), 1, { in: utc });
  const endDate =
    position === 0 ? new Date(lastPeriod.endDate) : endOfDayUTC(addDays(startDate, 4, { in: utc }));

  emptyWeek.period = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };

  weeksAfter = weeksAfter.map((w) => {
    const nextStartDate = addWeeks(parseISO(w.period.startDate), 1, { in: utc });
    const nextEndDate = addWeeks(parseISO(w.period.endDate), 1, { in: utc });

    return {
      ...w,
      period: {
        startDate: nextStartDate.toISOString(),
        endDate: nextEndDate.toISOString(),
      },
    };
  });

  return [...weeksBefore, emptyWeek, ...weeksAfter];
};

export const removeWeekAt = (position, weeks) => {
  const weeksBefore = [];
  let weeksAfter = [];

  weeks.forEach((w, index) => {
    if (index < position) weeksBefore.push(w);
    else if (index > position) weeksAfter.push(w);
  });

  weeksAfter = weeksAfter.map((w) => {
    const nextStartDate = subWeeks(parseISO(w.period.startDate), 1, { in: utc });
    const nextEndDate = subWeeks(parseISO(w.period.endDate), 1, { in: utc });

    return {
      ...w,
      period: {
        startDate: nextStartDate.toISOString(),
        endDate: nextEndDate.toISOString(),
      },
    };
  });

  return [...weeksBefore, ...weeksAfter];
};

export const moveStartDateWeeks = (startDate, weeks) => {
  const updatedWeeks = weeks.map((w, index) => {
    const nextStartDate = addWeeks(parseISO(startDate), index, { in: utc });
    const nextEndDate = endOfDayUTC(addDays(nextStartDate, 4, { in: utc }));

    return {
      ...w,
      period: {
        startDate: nextStartDate.toISOString(),
        endDate: nextEndDate.toISOString(),
      },
    };
  });

  return updatedWeeks;
};

function getActivitiesAfter(allActivities, startDate) {
  let activities = [];
  const parentActivities = allActivities.filter((a) => {
    return (
      !a.parent.href.includes('/llinkid/activityplanning/activityplans/activities/') &&
      a.period.unixStartDate >= startDate
    );
  });

  parentActivities.forEach((p) => {
    const childActivities = allActivities.filter(
      (activity) => activity.parent.href.indexOf(p.key) !== -1
    );
    activities.push(p);
    activities = [...activities, ...childActivities];
  });

  return activities;
}

export const getMoveWeekBatch = (allActivities, startDate, type = 'ADD') => {
  const activities = getActivitiesAfter(allActivities, startDate);
  const batch = [];

  activities.forEach((a) => {
    let period;
    if (type === 'ADD') {
      period = {
        startDate: addWeeks(a.period.dtStartDate, 1, { in: utc }).toISOString(),
        endDate: addWeeks(a.period.dtEndDate, 1, { in: utc }).toISOString(),
      };
    } else {
      period = {
        startDate: addWeeks(a.period.dtStartDate, -1, { in: utc }).toISOString(),
        endDate: addWeeks(a.period.dtEndDate, -1, { in: utc }).toISOString(),
      };
    }

    batch.push({
      verb: 'PUT',
      body: {
        $$attachments: a.$$attachments,
        $$meta: a.$$meta,
        description: a.description,
        goals: a.goals,
        key: a.key,
        parent: { href: a.parent.href },
        period,
        title: a.title,
      },
      href: a.$$meta.permalink,
    });
  });

  return batch;
};

export const validateUrl = (url) => {
  // eslint-disable-next-line prefer-regex-literals
  const re = new RegExp(
    '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'
  );

  return re.test(url);
};
