import {
  distinctBy,
  formatVersionNumber,
  getNonDerivedGroupKey,
  getNonDerivedRoot,
  isInVersion,
} from '@utils/utils';
import { last, orderBy, flatten } from 'lodash-es';
import {
  filterByOrgs,
  filterBySchoolyear,
  filterBySources,
  filterCustomCurriculaWithFilters,
} from '@utils/filters';
import { creatorType } from '../../constants/creatorType';
import { CUSTOMCURRICULATYPES } from '../../helpers/curriculumHelper';
import * as grades from '../../constants/grades';
import levelType from '../../constants/levelType';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const he = require('he');

export function getCustomCurriculaForTeamlead(customCurricula, team, teachers, schoolyear) {
  const filters = [filterByOrgs([team]), filterBySchoolyear(schoolyear)];

  const ccsForTeam = filterCustomCurriculaWithFilters(customCurricula, filters);

  if (!teachers) {
    // when does this happen?
    console.warn('no teachers!!?');
  }

  const visibleTeacherCCs = filterCustomCurriculaWithFilters(customCurricula, [
    filterByOrgs(teachers || team.teachers),
    filterBySchoolyear(schoolyear),
    filterBySources(ccsForTeam.map((e) => (e.source ? e.source.href : e.$$meta.permalink))),
  ]);

  return [...visibleTeacherCCs, ...ccsForTeam];
}

function getStreamsFinalitiesOfStudyPrograms(
  firstGradeStreams,
  secondGradeFinalities,
  studyPrograms
) {
  const streamsFinalities = new Set();
  const allStreamsFinalities = firstGradeStreams
    .concat(secondGradeFinalities)
    .map((s) => s.$$meta.permalink);
  const groups = new Set();
  studyPrograms.forEach((sp) =>
    sp.studyProgrammeGroups.forEach((spg) => groups.add(spg.studyProgrammeGroup.href))
  ); // /TODO check start/enddates of SPG

  for (const href of groups) {
    const streamOrFinality = allStreamsFinalities.find((s) => s === href);
    if (streamOrFinality) streamsFinalities.add(streamOrFinality);
  }

  return [...streamsFinalities];
}

function getOrderByLayer(type, isGrouped) {
  switch (type) {
    case 'SCHOOL':
      return isGrouped ? 6 : 3;
    case 'TEAM':
      return isGrouped ? 5 : 2;
    case 'TEACHER':
      return isGrouped ? 4 : 1;
    default:
      return 7;
  }
}

function filterStudyProgramGroupByApplicability(studyProgrammeGroup, applicability) {
  return (
    studyProgrammeGroup &&
    studyProgrammeGroup.studyProgrammes
      .filter((s) => applicability.studyProgrammes.map((sp) => sp.href).includes(s.href))
      .map((s) => last(s.href.split('/')))
      .join(',')
  );
}

/**
 * this is terrible because customStudyProgrammeGroups arent linked to sets yet, and it's unclear if they ever will. but once we step out of the automatic studyprogrammegroups we have to change this and link them propperly.
 * moreover it's possible that some groups have an overlap of studyprogrammes etc.
 */
function getStudyProgrammeGroup(studyPrograms, studyProgrammeGroups) {
  const groups = studyProgrammeGroups;
  const hrefs = studyPrograms.map((e) => e.$$meta.permalink);
  const curGroups = groups.filter((g) => g.studyProgrammes.some((sp) => hrefs.includes(sp.href)));
  return curGroups.length > 1 ? undefined : curGroups[0];
}

function translateCurricula({
  curriculum,
  customCurr,
  studyPrograms,
  layer,
  studyProgrammeGroup,
  bossOrgHrefs,
  currentSchoolyear,
  schoolStudyPrograms,
  groupid,
  allOrgs,
  firstGradeStreams,
  secondGradeFinalities,
  isTeamPrincipal,
  allCustomCurricula,
  isSchoolPrincipal,
  curriculaMultipleActiveVersionsMap,
}) {
  let grade;
  let stream;
  let name = he.decode(curriculum.title);
  const title = name;
  let { key } = curriculum;
  let versionNumber;

  if (customCurr) {
    customCurr.forEach((e) => {
      key += e.key;
    });

    if (studyPrograms && studyPrograms.length === 1) {
      name += ` - ${studyPrograms[0].title}`;
    } else if (studyProgrammeGroup) {
      name += ` (${studyProgrammeGroup.title})`;
    }

    if (layer === creatorType.team) {
      const team = allOrgs.find((_team) => _team.href === customCurr[0].creator.href);
      if (team) {
        name += ` - ${team.$$displayName}`;
      }
    } else if (layer === creatorType.teacher) {
      const pers = allOrgs.find((sch) => sch.href === customCurr[0].creator.href);
      if (pers) {
        name += ` - ${pers.$$displayName}`;
      }
    }
  }

  if (studyPrograms && studyPrograms.length > 0) {
    grade = studyPrograms[0].$$grade || ''; // /doesnt make functional sense to have a cur in multiple grades hence, just check the grade of the first SP.
    stream =
      getStreamsFinalitiesOfStudyPrograms(
        firstGradeStreams,
        secondGradeFinalities,
        studyPrograms
      ) || '';
  }

  if (curriculum.$$version) {
    versionNumber = formatVersionNumber({
      version: curriculum.$$version,
      grade,
      versionStatus:
        curriculaMultipleActiveVersionsMap && curriculaMultipleActiveVersionsMap[curriculum.key],
    });
  }

  let applicability;
  let studid;
  let sources;

  if (curriculum.type === 'LLINKID_CURRICULUM') {
    // selectablePrograms = curriculum.selectablePrograms;
    applicability = curriculum.applicability;
    if (studyProgrammeGroup) {
      studid = filterStudyProgramGroupByApplicability(
        studyProgrammeGroup,
        curriculum.applicability
      );
    } else if (studyPrograms && studyPrograms.length) {
      studid = last(studyPrograms[0].$$meta.permalink.split('/'));
    } else {
      studid = undefined;
    }
    if (studyPrograms) {
      sources = studyPrograms.map((s) => ({
        studyProgram: s.$$meta.permalink,
        href: curriculum.$$meta.permalink,
      }));
    } else {
      sources = [];
    }
  } else {
    applicability = {
      studyProgrammes: flatten(
        customCurr.map((c) => c.applicability).map((c) => c.studyProgrammes)
      ),
    };
    studid =
      studyPrograms && studyPrograms.length
        ? studyPrograms.map((sp) => last(sp.$$meta.permalink.split('/'))).join()
        : '';

    sources =
      customCurr &&
      customCurr.map((e) => ({
        href: getNonDerivedRoot(allCustomCurricula, e).$$meta.permalink,
        studyProgram: e.applicability.studyProgrammes[0].href,
      }));
  }

  let curricula;
  if (customCurr && customCurr.length > 1) {
    curricula = customCurr.map((e) =>
      translateCurricula({
        curriculum,
        customCurr: [e],
        studyPrograms: studyPrograms.filter(
          (item) => e.applicability.studyProgrammes[0].href === item.$$meta.permalink
        ),
        layer,
        bossOrgHrefs,
        currentSchoolyear,
        groupid: studyProgrammeGroup?.key,
        allOrgs,
        firstGradeStreams,
        secondGradeFinalities,
        isTeamPrincipal,
        allCustomCurricula,
        isSchoolPrincipal,
        schoolStudyPrograms,
        curriculaMultipleActiveVersionsMap,
      })
    );
  }

  let canEditCodeAndTitle = false;
  if (curriculum.type === CUSTOMCURRICULATYPES.custom) {
    if (customCurr && customCurr.length && customCurr[0].source === null) {
      if (
        (customCurr.length > 1 && customCurr[0].customCurriculaGroup) ||
        (customCurr.length === 1 && customCurr[0].customCurriculaGroup === null)
      ) {
        canEditCodeAndTitle = bossOrgHrefs.has(customCurr[0].creator.href);
      }
    }
  }

  let nonderivedGroupKey;
  if (curriculum.type === CUSTOMCURRICULATYPES.custom) {
    nonderivedGroupKey = getNonDerivedGroupKey(allCustomCurricula, customCurr[0]);
  }

  const type = layer || 'PLAN';
  const { foundational, okan } = curriculum;

  let showDistributeTeamButton = false;
  let showDistributeSchoolButton = false;

  if (foundational) {
    if (grade === grades.GRADE_2) {
      if (currentSchoolyear.startDate >= grades.grade2StartDate) {
        // only allow distribution of 2nd grade curricula in the schoolyear/version that starts when grade 2 started.
        showDistributeTeamButton = isTeamPrincipal;
        showDistributeSchoolButton = isSchoolPrincipal;
      }
    } else {
      showDistributeTeamButton = isTeamPrincipal;
      showDistributeSchoolButton = isSchoolPrincipal;
    }
  }

  let leerplanVersion;
  if (currentSchoolyear.key <= '2021-2022') {
    if (customCurr && customCurr[0]) {
      const { issued } = customCurr[0];
      leerplanVersion = currentSchoolyear.versions.find((e) =>
        isInVersion(issued.startDate, issued.endDate, e)
      );
    }
  }

  let relevant = true;
  if (!okan && type === 'PLAN' && schoolStudyPrograms) {
    const isRelevant = applicability
      ? applicability.studyProgrammes.find((e) => schoolStudyPrograms.has(e.href))
      : null;
    relevant = isRelevant != null;
  }

  const isNonDerived = curriculum.type === CUSTOMCURRICULATYPES.custom;

  const customCurriculaGroup =
    customCurr && customCurr[0].customCurriculaGroup && customCurr[0].customCurriculaGroup.href;
  const customCurriculum =
    customCurr && customCurr.length === 1 ? customCurr[0].$$meta.permalink : undefined;

  return {
    key,
    curriculumHref: customCurriculum || customCurriculaGroup || curriculum.$$meta.permalink,
    id: isNonDerived ? 'nonderived' : curriculum.key,
    sources, // sources is used when creating new curricula on lower levels.
    fav: false,
    type,
    year: '',
    title,
    name,
    // selectablePrograms, this is set later.
    order: getOrderByLayer(layer || 'PLAN', false),
    isGrouped: customCurr && customCurr.length > 1,
    custid: customCurr && customCurr.length === 1 ? customCurr[0].key : undefined,
    foundational,
    okan,
    identifiers: Array.isArray(curriculum.identifiers)
      ? curriculum.identifiers.join('')
      : curriculum.identifiers || curriculum.identifier,
    applicability,
    studid,
    studyProgram:
      layer && studyPrograms && studyPrograms.length
        ? studyPrograms.map((sp) => sp.$$meta.permalink).join()
        : applicability.studyProgrammes.map((e) => e.href).join(','),
    grade,
    stream,
    curricula,
    creator: customCurr && customCurr[0].creator.href,
    context: customCurr && customCurr[0].context.href,
    version: leerplanVersion,
    latest: leerplanVersion?.accentColor || false,
    customCurriculaGroup,
    studyProgrammeGroup,
    groupid: studyProgrammeGroup?.key || groupid,
    setid:
      customCurr && customCurr.length > 1 && customCurr[0].customCurriculaGroup
        ? last(customCurr[0].customCurriculaGroup.href.split('/'))
        : undefined, // used for favoriting.
    customCurriculum,
    customCurriculumParameter:
      customCurr && customCurr.length === 1 && !customCurr[0].customCurriculaGroup
        ? customCurr[0].key
        : undefined,
    customCurriculaGroupParameter:
      customCurr && customCurr.length && customCurr[0].customCurriculaGroup
        ? last(customCurr[0].customCurriculaGroup.href.split('/'))
        : undefined,
    canEditCodeAndTitle,
    nonderivedGroupKey,
    visible: relevant,
    showSchool: false, // showing the create school cur button (never)
    showTeam:
      !okan &&
      (type === 'SCHOOL' || type === 'PLAN') &&
      !foundational &&
      isTeamPrincipal &&
      !isNonDerived, // showing th create team cur button
    showPersonal:
      !okan && type !== 'TEACHER' && !foundational && allOrgs.length > 0 && !isNonDerived, // showing the create personal curricula button
    showDistributeTeamButton,
    showDistributeSchoolButton,
    relevant,
    versionNumber,
    src: levelType.properties?.[type]?.src,
  };
}

function translateCustomCurricula({
  sourceCurricula,
  customCurricula,
  grouped,
  schoolyear,
  allStudyPrograms,
  allOrgs,
  bossOrgHrefs,
  studyProgrammeGroups,
  firstGradeStreams,
  secondGradeFinalities,
  isTeamPrincipal,
  allCustomCurricula,
  isSchoolPrincipal,
  schoolStudyPrograms,
  curriculaMultipleActiveVersionsMap,
}) {
  const orgs = allOrgs;
  const handledCustomCurricula = new Set();
  const filteredList = [];
  customCurricula.forEach((customCurr) => {
    if (!handledCustomCurricula.has(customCurr.key)) {
      let groupedCc = [customCurr];
      let studyProgrammeGroup;

      if (grouped && customCurr.customCurriculaGroup) {
        groupedCc = customCurricula.filter(
          (e) =>
            e.customCurriculaGroup &&
            e.customCurriculaGroup.href === customCurr.customCurriculaGroup.href
        );
        groupedCc.forEach((e) => handledCustomCurricula.add(e.key));
      }

      const curriculum = sourceCurricula.find(
        (item) => customCurr.source && item.$$meta.permalink === customCurr.source.href
      );
      const baseNonDerived = customCurricula.find(
        (item) => customCurr.source && item.$$meta.permalink === customCurr.source.href
      );
      const studyPrograms = allStudyPrograms.filter((item) =>
        groupedCc.some((cc) => cc.applicability.studyProgrammes[0].href === item.$$meta.permalink)
      );
      if (grouped && customCurr.customCurriculaGroup) {
        studyProgrammeGroup = getStudyProgrammeGroup(studyPrograms, studyProgrammeGroups);
      }

      const currentOrg = orgs.find((org) => org.href === customCurr.creator.href);
      if (currentOrg) {
        /// user might not be in the school anymore.
        if (curriculum || baseNonDerived || CUSTOMCURRICULATYPES.custom === customCurr.type) {
          const listItem = translateCurricula({
            curriculum: curriculum || baseNonDerived || customCurr,
            customCurr: groupedCc,
            studyPrograms,
            layer: currentOrg.creatorType,
            studyProgrammeGroup,
            bossOrgHrefs,
            allOrgs,
            currentSchoolyear: schoolyear,
            firstGradeStreams,
            secondGradeFinalities,
            isTeamPrincipal,
            allCustomCurricula,
            isSchoolPrincipal,
            schoolStudyPrograms,
            curriculaMultipleActiveVersionsMap,
          });
          filteredList.push(listItem);
        }
      }
    }
  });

  return filteredList;
}

function checkDeletion(curricula, userHref, principalTeamHrefs, principalSchoolHrefs) {
  curricula.forEach((c) => {
    c.canDelete =
      (c.type === 'TEACHER' && c.creator === userHref) ||
      (c.type === 'TEAM' && principalTeamHrefs.includes(c.creator)) ||
      (c.type === 'SCHOOL' && principalSchoolHrefs.includes(c.creator));

    if (c.curricula && c.curricula.length) {
      c.curricula.forEach((cc) => {
        cc.canDelete = c.canDelete;
      });
    }
  });

  return curricula;
}

function sortCurriculaByIdentifiers(curriculaList) {
  return orderBy(curriculaList, ['fav', 'order', 'name'], ['desc', 'asc', 'asc']);
}

/**
 * we have a few lists that we need.
 * we have the grouped/ungrouped covered.
 * we have assumed the getAll = false, which means we're filtering out the OUs the user doesn't have access to
 *  - like: other teachers' curricula
 *  - teams you're not a member of their curricula
 * the getCurriculaList and getFlatCurriculaList have the getAll=true option where you get the list for ALL OUs within the school (all teams, all teachers)
 * getCurriculaList has the option to query ungrouped, but this is not used anywhere anymore, so can be ignored. grouped is always true.
 * the getCurriculaList and getFlatCurriculaList have a schoolyear property.
 *  - in the copy modal for curricula, the getFlatCurriculaList is called with a non-current schoolyear (to copy to the next schoolyear)
 *  - everywhere else it's using the current schoolyear.
 *  - ==> this seems to be the old copy modal. there is a new one in React.
 * the getCurriculaBySchoolyearForCalendar function in homeService is calling the getCurriculaList with future schoolyears for the react copymodal.
 * how do we make all of this possible? => extract all the logic out of the selector, into a function.
 * then the function can be called from multiple selectors, and will return the list it needs to.
 * in case of the copy modal, to get the list of a future schoolyear, it could call the function directly, perhaps,
 * or there is a selector that returns the list for all schoolyears, and the copy modal would have to filter it by itself.
 * we want to avoid always calculating the homepageList for all schoolyears, as it will not be performant.
 */

export function generateGroupedHomePageList(
  $curriculaRoots,
  customCurricula,
  $orgsInfo,
  principalSchoolHrefs,
  principalTeamHrefs,
  allPrograms,
  firstGradeStreams,
  secondGradeFinalities,
  currentSchoolyearKey,
  $userHref,
  getAll,
  schoolyears,
  curriculaMultipleActiveVersionsMap
) {
  if (!$curriculaRoots?.length || !currentSchoolyearKey) {
    return [];
  }
  console.time('groupedHomePageListSelector');
  const currentSchoolyear = schoolyears.find((e) => e.key === currentSchoolyearKey);
  const {
    userOrgs,
    orgs,
    schoolHref,
    schoolStudyProgrammes,
    studyProgrammeGroups,
    principalTeamsWithTeachers,
  } = $orgsInfo;
  let schoolStudyPrograms = null;
  // console.log('currentSchool');
  // console.log(currentSchool);
  let ownCustomCurricula = [];
  let bossOrgHrefs = new Set();

  let isSchoolPrincipal = false;
  let isTeamPrincipal = false;
  if (schoolHref) {
    if (principalSchoolHrefs.includes(schoolHref)) {
      isSchoolPrincipal = true;
    }
    const myOrgHrefs = new Set(userOrgs.map((e) => e.href));
    schoolStudyPrograms = new Set(schoolStudyProgrammes);

    const filters = [filterBySchoolyear(currentSchoolyear)];
    if (getAll !== true) filters.push(filterByOrgs(userOrgs));

    ownCustomCurricula = filterCustomCurriculaWithFilters(customCurricula, filters);

    if (principalTeamsWithTeachers.length) {
      isTeamPrincipal = true;

      principalTeamsWithTeachers.forEach((team) => {
        const teachers = team.teachers.filter((t) => !myOrgHrefs.has(t.href)); // only need to get the custom curricula for persons that aren't in the orgs
        if (teachers.length) {
          const teamLeadCustomCurricula = getCustomCurriculaForTeamlead(
            customCurricula,
            team,
            teachers,
            currentSchoolyear
          );
          ownCustomCurricula = ownCustomCurricula.concat(teamLeadCustomCurricula);
        }
      });
      ownCustomCurricula = distinctBy(ownCustomCurricula, 'key');
    }
    bossOrgHrefs = new Set([...principalSchoolHrefs, ...principalTeamHrefs]); // why do we need this?
  }

  const allStudyPrograms = allPrograms;
  const baseCurricula = $curriculaRoots;

  // console.time('groupedHomePageListSelector-translatedCurricula');
  const translatedCurricula = baseCurricula.map((curr) => {
    let studyPrograms = null;

    if (curr.applicability) {
      const spHrefs = new Set(curr.applicability.studyProgrammes.map((e) => e.href));
      studyPrograms = allStudyPrograms.filter((item) => spHrefs.has(item.$$meta.permalink));
    }

    return translateCurricula({
      curriculum: curr,
      studyPrograms,
      bossOrgHrefs,
      currentSchoolyear,
      schoolStudyPrograms,
      allOrgs: orgs,
      firstGradeStreams,
      secondGradeFinalities,
      isTeamPrincipal,
      isSchoolPrincipal,
      curriculaMultipleActiveVersionsMap,
    });
  });
  // console.timeEnd('groupedHomePageListSelector-translatedCurricula');
  // console.time('groupedHomePageListSelector-translatedCustomCurricula');
  const translatedCustomCurricula = translateCustomCurricula({
    sourceCurricula: baseCurricula,
    customCurricula: ownCustomCurricula,
    grouped: true,
    schoolyear: currentSchoolyear,
    allOrgs: orgs,
    bossOrgHrefs,
    studyProgrammeGroups,
    allStudyPrograms,
    firstGradeStreams,
    secondGradeFinalities,
    isTeamPrincipal,
    allCustomCurricula: customCurricula,
    isSchoolPrincipal,
    schoolStudyPrograms,
    curriculaMultipleActiveVersionsMap,
  });
  // console.timeEnd('groupedHomePageListSelector-translatedCustomCurricula');
  const curricula = [...translatedCurricula, ...translatedCustomCurricula];

  checkDeletion(curricula, $userHref, principalTeamHrefs, principalSchoolHrefs);
  const sorted = sortCurriculaByIdentifiers(curricula);
  console.timeEnd('groupedHomePageListSelector');
  // console.log('groupedHomePageListSelectorResult:', sorted.length, 'items');
  return sorted;
}

export const mapCurriculaToParams = (curricula) => {
  return curricula.map((cur) => {
    const { id, custid, groupid, studid, setid } = cur;
    return {
      name: cur.name + (cur.versionNumber ? ` (${cur.versionNumber})` : ''),
      custid,
      groupid,
      studid,
      setid,
      id,
    };
  });
};
