import { createSelector } from '@reduxjs/toolkit';
import { CONTENTAPI_DOCUMENT_KEYS } from '@store/contentApi/contentApiDataAccess';
import {
  selectCurriculaWithMultipleActiveVersions,
  selectEducationalComponents,
  selectEducationalPointers,
} from '@store/contentApi/contentApiSelectors';
import { selectLeidraadTop5AndEduPointers } from '@store/leidraad/leidraadSelectors';
import {
  selectCustomCurriculaData,
  selectCustomItemsInFormat,
} from '@store/llinkidApis/llinkidApiSelectors';
import {
  selectAllCreatorsActiveInSchoolyear,
  selectAllCreatorsForSchoolThatEverExisted,
  selectBossOrgHrefs,
  selectCurrentOrg,
  selectCurrentSchoolHref,
  selectCurrentSchoolyear,
  selectIsSchoolyearReadOnly,
  selectSchoolyearFromParamOrCurrent,
  selectUser,
} from '@store/userAndSchool/userAndSchoolSelectors';
import {
  CONTENTTYPES,
  CUSTOMCURRICULATYPES,
  CUSTOMTYPES,
  TABNAMES,
  excludeMandatoryResources,
  flatRefrencedCurriculaItems,
  flattenAndExtractCurriculaItems,
  getCustomGoalList,
  modifyGoalsForDistribution,
  setCollapsedSections,
} from '@utils/curriculumHelper';
import {
  conditionalLogTime,
  deepFreeze,
  formatVersionNumber,
  getViewModelEntities,
  validityPeriodToString,
} from '@utils/utils';
import { getKeyFromHref } from '@utils/getKeyFromHref';
import { isEqual, last } from 'lodash-es';
// import { diffString } from 'json-diff';

import { selectSchoolStudyProgrammes } from '@store/leerplanList/leerplanListSelectors';
import { logAndCaptureException } from '@utils/logAndCaptureException';
import {
  addDraggable,
  buildAnnotations,
  buildGoalListItems,
  buildGoalListTab,
  buildGoalsFromExtractedCurriculumNodes,
  buildHeaderInfo,
  buildIntroductionTab,
  buildRichTextTab,
  getCurriculumKeyFromStateOrParams,
  getCustomCurriculaFromIds,
  getCustomCurriculaFromSetId,
  getCustomCurriculasInOrder,
  getGoalsToLoad,
  getGradeFromCurriculum,
  getItemsFromReferences,
  getLayeredAnnotationsObj,
  getTitleObjects,
  triggerSelectedItems,
} from './curriculumAndAnnotationHelper';
import CurriculumNode from '../../module/models/curriculumNode';

const emptyArray = [];

export const selectCustomCurriculumFromKey = (state, key) => {
  const customCurricula = selectCustomCurriculaData(state);
  return customCurricula.find((e) => e.key === key);
};

export const selectIsSelectionReadonlyMode = (state) =>
  state.curriculum.selectionOptions.readonlyMode;

export const selectCustomCurriculaFromUrlOrParams = createSelector(
  [
    (state, params) => (params ? params.setid : state.curriculum.setid),
    (state, params) => (params ? params.studids : state.curriculum.studids),
    (state, params) => (params ? params.custids : state.curriculum.custids),
    selectCustomCurriculaData,
  ],
  (setid, studids, custids, allCustomCurriculas) => {
    return getCustomCurriculaFromIds(setid, studids, custids, allCustomCurriculas);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectIsBaseCurriculum = (state) => !selectCustomCurriculaFromUrlOrParams(state);

export const selectCustomGoalIdentifiersSet = createSelector(
  [(state) => state.customCurriculaData.customItems],
  (customItems) =>
    new Set(
      Object.values(customItems)
        .map((item) => item.identifier)
        .filter(Boolean)
    )
);

export const selectUniqueGoalId = (state, params) => {
  let idNumber = 1;
  let newId = `${params.curriculumId} ${idNumber}`;
  const identifiersSet = selectCustomGoalIdentifiersSet(state);

  while (identifiersSet.has(newId)) {
    idNumber += 1;
    newId = `${params.curriculumId} ${idNumber}`;
  }

  return newId;
};

/**
 * this selector returns ALL of the customcurricula that match the setid.
 * the difference with the selector above is that this one returns all the customcurricula, and ignores the current studids.
 * the use case is for the distribute modal where we need to filter studyprogrammes that are not available for this set
 */
const selectCustomCurriculaFromSetid = createSelector(
  [(state, params) => (params ? params.setid : state.curriculum.setid), selectCustomCurriculaData],
  (setid, allCustomCurriculas) => {
    if (!setid) return emptyArray;
    return getCustomCurriculaFromSetId(setid, allCustomCurriculas);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

const selectNonDerivedTopLevelCurricula = createSelector(
  [
    (state, params) => (params ? params.setid : state.curriculum.setid),
    selectCustomCurriculaFromSetid,
    selectCustomCurriculaFromUrlOrParams,
    (state) => state.customCurriculaData.customCurricula,
  ],
  (setid, customCurriculaFromSetId, customCurriculaFromUrlOrParams, allCustomCurriculasMap) => {
    // in the case of the smartschool curriculum selector, there is no setId when opening a non-derived.
    // so we fall back to getting the customcurricula from the url via custids.
    const customCurriculaFromUrl = setid
      ? customCurriculaFromSetId
      : customCurriculaFromUrlOrParams;
    if (!customCurriculaFromUrl) return emptyArray;

    if (customCurriculaFromUrl.every((z) => z.source === null)) {
      return customCurriculaFromUrl;
    }
    if (
      customCurriculaFromUrl[0].type === CUSTOMCURRICULATYPES.adaptation &&
      !customCurriculaFromUrl[0].source.href.startsWith('/content')
    ) {
      return customCurriculaFromUrl.map(
        (item) => allCustomCurriculasMap[last(item.source.href.split('/'))]
      );
    }
    logAndCaptureException('not a nonderived');
    return undefined;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectCurriculumKey = (state) => state.curriculum.curriculumKey;

export const selectCurriculumStudyProgrammeHrefs = createSelector(
  [
    (state, params) =>
      getCurriculumKeyFromStateOrParams(state, params) === 'nonderived'
        ? selectNonDerivedTopLevelCurricula(state, params)
        : undefined,
    (state, params) =>
      state.contentApiData.curricula[getCurriculumKeyFromStateOrParams(state, params)],
    (state) => state.contentApiData.curriculaRoots,
    (state, params) => getCurriculumKeyFromStateOrParams(state, params),
  ],
  (NDTopLvlCur, baseCurricula, allBaseCurriculaFromList, curriculumKey) => {
    /**
     * the baseCurriculum comes from an api call /content/guid/llinkidCurricula
     * that data is possibly undefined if that call is still loading.
     * we do have all root nodes for the list screen in curriculaRoots, and the root contains the studyprogrammes, so we use that as a fallback.
     */
    let curriulumStudyProgrammes = [];
    if (NDTopLvlCur) {
      curriulumStudyProgrammes = NDTopLvlCur.map((z) => z.applicability.studyProgrammes).flat();
    } else {
      const definedBaseCurricula =
        baseCurricula || allBaseCurriculaFromList.find((e) => e.key === curriculumKey);
      curriulumStudyProgrammes = definedBaseCurricula?.applicability?.studyProgrammes || [];
    }
    return curriulumStudyProgrammes;
  }
);

export const selectDoCustomCurriculumStudyProgrammesMatchSchoolStudyProgrammes = createSelector(
  [selectCurriculumStudyProgrammeHrefs, selectSchoolStudyProgrammes],
  (curriculumStudyProgrammes, schoolStudyProgrammes) =>
    curriculumStudyProgrammes?.some((curriculumStudyProgram) =>
      schoolStudyProgrammes?.some(
        (schoolStudyProgram) => schoolStudyProgram.$$meta.permalink === curriculumStudyProgram.href
      )
    )
);

export const selectCustomCurriculaStudyProgrammeHrefs = createSelector(
  [selectCustomCurriculaFromSetid],
  (customCurricula) => {
    if (!customCurricula) return emptyArray;
    return customCurricula
      .map((z) => z.applicability.studyProgrammes)
      .flat()
      .map((z) => z.href);
  }
);

export const selectCurriculumGrade = (state, params) => {
  const curriculumStudyProgrammes = selectCurriculumStudyProgrammeHrefs(state, params);
  if (!curriculumStudyProgrammes || curriculumStudyProgrammes.length === 0) return undefined;
  const fakeCurriculum = { applicability: { studyProgrammes: curriculumStudyProgrammes } };
  return getGradeFromCurriculum(fakeCurriculum, state.studyProgrammeApiData.allPrograms);
};

export const selectGoalList = (state) => {
  const originalCurriculum =
    state.contentApiData.curricula[getCurriculumKeyFromStateOrParams(state)];
  if (!originalCurriculum) return undefined;
  const goalList = originalCurriculum.children.find((e) => e.type === CONTENTTYPES.goalList);
  return goalList;
};

const selectExtractedCurriculum = createSelector(
  [
    (state, params) =>
      state.contentApiData.curricula[getCurriculumKeyFromStateOrParams(state, params)],
  ],
  (originalCurriculum) => {
    if (!originalCurriculum) return undefined;
    const goalList = originalCurriculum.children.find((e) => e.type === CONTENTTYPES.goalList);
    const flats = flattenAndExtractCurriculaItems(goalList);
    return flats;
  }
);

export const selectBaseCurriculumViewModel = createSelector(
  [
    (state, params) => getCurriculumKeyFromStateOrParams(state, params),
    (state, params) =>
      state.contentApiData.curricula[getCurriculumKeyFromStateOrParams(state, params)],
    selectEducationalComponents,
    selectEducationalPointers,
    (state, params) =>
      state.contentApiData.documents[
        CONTENTAPI_DOCUMENT_KEYS.INTRODUCTION_GRADE[selectCurriculumGrade(state, params)]
      ],
    selectCurriculumGrade,
    (state) =>
      selectCurriculaWithMultipleActiveVersions(state, {
        schoolyearKey: state.userAndSchools.currentSchoolyear,
      }),
  ],
  (
    curriculumKey,
    originalCurriculum,
    edComponents,
    edPointers,
    introduction,
    grade,
    curriculaMultipleActiveVersionsMap
  ) => {
    if (!originalCurriculum || !curriculumKey || curriculumKey === 'nonderived') return undefined;
    const endLogTime = conditionalLogTime('selectBaseCurriculumViewModel', 2);
    const curriculum = { ...originalCurriculum };
    let viewModel = {
      inleiding: {},
      situering: {},
      duiding: {},
      basisuitrusting: {},
      concordantielijst: {},
      generalLevel: {},
      educationalPointers: [],
      educationalComponents: [],
      curriculumType: 'PLAN',
      subTitle: '',
      studyProgram: '',
      readonly: true,
      grade,
    };

    viewModel.versionNumber = formatVersionNumber({
      version: curriculum.version,
      grade,
      versionStatus: curriculaMultipleActiveVersionsMap?.[curriculum.key],
    });

    viewModel.inleiding = introduction && buildIntroductionTab(introduction);
    // the idea for inleiding is, that it will have its own VM, since it's the same for every curriculum of the same grade.
    // only load the inleiding when we need it visualized.
    viewModel.situering = buildRichTextTab(curriculum, 'situering');
    viewModel.duiding = buildRichTextTab(curriculum, 'duiding');
    viewModel.basisuitrusting = buildRichTextTab(curriculum, 'basisuitrusting');
    viewModel.concordantielijst = buildRichTextTab(curriculum, 'concordantielijst');

    viewModel = { ...viewModel, ...curriculum, children: undefined };

    const principleGroup = curriculum.children.find(
      (res) => res.type === CONTENTTYPES.principleGroup
    );
    viewModel.generalLevel = principleGroup || {};

    viewModel = buildHeaderInfo(viewModel, edPointers, edComponents);

    viewModel.validityPeriodString = validityPeriodToString(curriculum.validityPeriod);
    viewModel.studyPrograms = viewModel.applicability?.studyProgrammes.map((i) =>
      last(i.href.split('/'))
    );
    endLogTime();
    return viewModel;
  }
);

export const selectCreatorFromCustomCurricula = createSelector(
  [
    (state) => selectCustomCurriculaFromUrlOrParams(state),
    (state) => selectAllCreatorsForSchoolThatEverExisted(state),
  ],
  (customCurFromUrl, currentOrgs) => {
    if (customCurFromUrl?.length) {
      return currentOrgs.find((org) => org.$$meta.permalink === customCurFromUrl[0].creator.href);
    }
    return null;
  }
);

export const selectCreator = (state) => {
  return selectCreatorFromCustomCurricula(state) || selectCurrentOrg(state);
  // current org depends.
  // if we are in a customcur, the current org is the creator of the customcur.
  // if we are in a base curricula, the current org is whatever is in the state. (distribution screen)
};

export const selectCreatorFromCurriculumNiveau = (state, curriculum) => {
  const user = selectUser(state);
  const currentOrg = selectCurrentOrg(state);

  switch (curriculum.niveau) {
    case 'school':
      return currentOrg.$$meta.permalink;
    case 'group':
      return curriculum.group;
    case 'persoonlijk':
      return user.$$meta.permalink;
    default:
      return currentOrg.$$meta.permalink;
  }
};

export const selectCurriculumAttachments = (state, params) =>
  state.contentApiData.curricula[params?.curriculumKey || state.curriculum.curriculumKey]
    ?.attachments || emptyArray;

export const selectIsCurriculumFoundational = (state) =>
  state.contentApiData.curricula[state.curriculum.curriculumKey]?.foundational;

export const selectSelectedStudyPrograms = createSelector(
  [
    selectCustomCurriculaFromUrlOrParams,
    (state) => state.curriculum.studids,
    (state) => state.studyProgrammeApiData.allPrograms,
  ],
  (customCurFromUrl, studids, allPrograms) => {
    if (allPrograms && (studids?.length || customCurFromUrl?.length)) {
      let spKeys = studids;
      if (customCurFromUrl) {
        // in case of a customcur, we look at the customcur. in case of a foundational/distribution we look at the studids because no customcur exist.
        spKeys = customCurFromUrl.map((e) =>
          getKeyFromHref(e.applicability.studyProgrammes[0].href)
        );
      }
      return allPrograms.filter((e) => spKeys.includes(e.key));
    }
    return emptyArray;
  }
);

export const selectBaseNonDerivedCurriculumViewModel = createSelector(
  [
    (state, params) => getCurriculumKeyFromStateOrParams(state, params),
    selectCustomCurriculaFromUrlOrParams,
    selectCurriculumGrade,
    selectNonDerivedTopLevelCurricula,
  ],
  (
    curriculumKey,
    customCurricula,
    grade,
    // curriculaMultipleActiveVersionsMap,
    nonderivedTopLevelCurricula
  ) => {
    if (nonderivedTopLevelCurricula.length === 0 || curriculumKey !== 'nonderived')
      return undefined;
    const endLogTime = conditionalLogTime('selectBaseNonDerivedCurriculumViewModel', 2);

    const item = nonderivedTopLevelCurricula[0];

    const studyPrograms = customCurricula.map((e) =>
      getKeyFromHref(e.applicability.studyProgrammes[0].href)
    );

    const viewModel = {
      title: item.title,
      inleiding: {},
      situering: {},
      duiding: {},
      basisuitrusting: {},
      concordantielijst: {},
      generalLevel: {},
      educationalPointers: [],
      educationalComponents: [],
      // curriculumType: 'PLAN',
      subTitle: '',
      studyProgram: '',
      studyPrograms,
      readonly: true,
      grade,
      identifiers: [item.identifier],
      doelenlijst: getCustomGoalList(),
      nonderivedGroupKey: item.customCurriculaGroup
        ? item.customCurriculaGroup.href
        : item.$$meta.permalink,
      version: formatVersionNumber({ version: item.$$version }), // emulate API
      versionNumber: formatVersionNumber({ version: item.$$version }), // emulate baseCurVM
      validityPeriodString: validityPeriodToString(customCurricula[0].issued),
    };

    endLogTime();
    return viewModel;
    // return viewModel;
  }
);

const selectAllGoalFromCurricula = createSelector(
  [(state) => state.contentApiData.curricula],
  (curricula) => {
    const goals = {};
    Object.values(curricula).forEach((curr) => {
      flatRefrencedCurriculaItems(curr)
        .filter((e) => e.type === CONTENTTYPES.goal || e.type === CUSTOMTYPES.goal)
        .forEach((goal) => {
          goals[goal.key] = goal;
        });
    });
    return goals;
  }
);

const selectAllGoalFromGoals = createSelector(
  [(state) => state.contentApiData.goals],
  (goalsWithChildren) => {
    const goals = {};
    Object.values(goalsWithChildren).forEach((curr) => {
      flatRefrencedCurriculaItems(curr)
        .filter((e) => e.type === CONTENTTYPES.goal)
        .forEach((goal) => {
          goals[goal.key] = goal;
        });
    });
    return goals;
  }
);

const selectAllCustomGoals = createSelector([selectCustomItemsInFormat], (customItems) => {
  const goals = {};
  Object.values(customItems).forEach((curr) => {
    if (curr.type === CUSTOMTYPES.goal) {
      goals[curr.key] = curr;
    }
  });
  return goals;
});

export const selectAllGoals = createSelector(
  [selectAllGoalFromCurricula, selectAllGoalFromGoals, selectAllCustomGoals],
  (allGoalsFromCur, extraGoals, customGoals) => {
    return { ...allGoalsFromCur, ...extraGoals, ...customGoals };
  }
);

export const selectBuiltGoals = (state, goalHrefs) => {
  const edPointers = selectEducationalPointers(state);
  const { educationalActivityTypes } = state.studyProgrammeApiData;
  const allGoals = selectAllGoals(state);

  const goals = goalHrefs.map((href) => allGoals[getKeyFromHref(href)]).filter((e) => e);

  if (!goals.length) return goals;

  const builtGoals = buildGoalsFromExtractedCurriculumNodes(
    goals,
    edPointers,
    educationalActivityTypes
  );

  return builtGoals;
};

/**
 *
 * @param {*} state
 * @returns an object with which tryCreateCustomCurricula can be called in the dataservice, or false.
 */
export const selectAreCustomCurriculaMissing = (state) => {
  const customCurricula = selectCustomCurriculaFromUrlOrParams(state);
  const { studids } = state.curriculum;
  const { allPrograms } = state.studyProgrammeApiData;
  const schoolyear = selectCurrentSchoolyear(state);

  if (!customCurricula || !customCurricula[0]) return false;

  const curriculasSPKeys = customCurricula.map((e) =>
    getKeyFromHref(e.applicability.studyProgrammes[0].href)
  );

  if (studids?.length && !isEqual([...studids].sort(), curriculasSPKeys.sort())) {
    // studids might be undefined, coming from smartschool
    const curriculum = customCurricula[0];
    if (curriculum.source && curriculum.source.href.startsWith('/content')) {
      const hrefs = allPrograms
        .filter((s) => studids.includes(s.key))
        .map((e) => e.$$meta.permalink);
      return {
        curriculum: {
          curriculumType: curriculum.type,
          sources: hrefs.map((s) => ({
            studyProgram: s,
            href: curriculum.source.href,
          })),
        },
        creator: curriculum.creator.href,
        studyPrograms: hrefs,
        schoolyear,
      };
    }
  }
  return false;
};

/**
 * returns all goal hrefs that are referenced in the curriculum.
 * it does not return hrefs to custom goals.
 */
const selectReferencedGoalHrefsFromCurriculum = createSelector(
  [
    (state, params) =>
      params && !params.loadFromUrl
        ? selectCustomCurriculaFromUrlOrParams(state, params)
        : selectCustomCurriculaFromUrlOrParams(state),
    (state, params) => selectAllCreatorsActiveInSchoolyear(state, params),
    (state, params) => selectSchoolyearFromParamOrCurrent(state, params),
    (state) => state.customCurriculaData.annotations,
    (state) => state.customCurriculaData.customCurricula,
  ],
  (customCurricula, orgs, currentSchoolyear, allAnnotationsMap, allCustomCurriculasMap) => {
    if (!customCurricula) return emptyArray;

    const customCurriculasInOrderObj = getCustomCurriculasInOrder(
      customCurricula,
      orgs,
      currentSchoolyear,
      allCustomCurriculasMap
    );

    const customCurHrefs = [];
    Object.keys(customCurriculasInOrderObj).forEach((key) => {
      const custCurInOrder = customCurriculasInOrderObj[key];
      custCurInOrder.forEach((cc) => customCurHrefs.push(cc.$$meta.permalink));
    });

    const annotations = Object.values(allAnnotationsMap).filter(
      (annotation) =>
        customCurHrefs.includes(annotation.curriculum.href) &&
        annotation.type === CUSTOMTYPES.goalReference // only references would need translation
    );

    const goalsFoundInCur = new Set();

    annotations.forEach((annotation) => {
      if (annotation.target.href.startsWith('/content')) {
        goalsFoundInCur.add(annotation.target.href);
      }
    });

    return [...goalsFoundInCur];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

// find missingGoals
export const selectExtraGoalsToLoad = createSelector(
  [
    selectReferencedGoalHrefsFromCurriculum,
    (state) => state.curriculum.preview,
    selectAllGoals,
    (state) => state.contentApiData.goalsToLoad,
  ],

  (goalsToLoadHrefs, preview, allGoals, goalsToLoadStatuses) => {
    return getGoalsToLoad(goalsToLoadHrefs, allGoals, goalsToLoadStatuses, preview);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectIsCurriculumLoaded = createSelector(
  [
    (state, params) => params?.curriculumKey || state.curriculum.curriculumKey,
    selectReferencedGoalHrefsFromCurriculum,
    (state, params) =>
      state.contentApiData.curricula[params?.curriculumKey || state.curriculum.curriculumKey],
    (state, params) =>
      state.contentApiData.curriculaToLoad[params?.curriculumKey || state.curriculum.curriculumKey],
    selectAllGoals,
    (state) => state.contentApiData.goalsToLoad,
  ],
  (curriculumKey, referencedGoalHrefs, curricula, curriculaToLoad, allGoals, goalsToLoad) => {
    const isBaseCurrLoaded =
      curriculumKey === 'nonderived' || !!curricula || curriculaToLoad?.isFailed === true;

    const isLoaded =
      isBaseCurrLoaded &&
      referencedGoalHrefs.every((e) => {
        const key = getKeyFromHref(e);
        return allGoals[key] || goalsToLoad[key]?.isFailed;
      });
    return isLoaded;
  },
  {
    memoizeOptions: {},
  }
);

export const selectLayeredAnnotationsObjFromUrlOrParams = createSelector(
  [
    selectCustomCurriculaFromUrlOrParams,
    (state, params) => selectSchoolyearFromParamOrCurrent(state, params),
    (state, params) => selectAllCreatorsActiveInSchoolyear(state, params),
    (state) => state.customCurriculaData.annotations,
    (state) => selectCustomItemsInFormat(state),
    (state) => state.customCurriculaData.customCurricula,
    selectAllGoals,
  ],
  (
    customCurricula,
    currentSchoolyear,
    orgs,
    annotationsMap,
    customitemsMap,
    customCurriculaMap,
    goalsMap
  ) => {
    const layeredAnnotationsObj = getLayeredAnnotationsObj({
      customCurricula,
      currentSchoolyear,
      orgs,
      annotationsMap,
      customCurriculaMap,
    });
    const referencedItems =
      layeredAnnotationsObj &&
      getItemsFromReferences(layeredAnnotationsObj, customitemsMap, goalsMap);
    return deepFreeze({ layeredAnnotationsObj, referencedItems });
  },
  {
    memoizeOptions: {
      // resultEqualityCheck: deepEqualWithLog('selectLayeredAnnotationsObjFromUrlOrParams'),
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectAnnotatedCurriculumGoalListData = createSelector(
  [
    (state, params) => getCurriculumKeyFromStateOrParams(state, params),
    selectEducationalPointers,
    (state) => state.studyProgrammeApiData.educationalActivityTypes,
    selectExtractedCurriculum,
    selectLayeredAnnotationsObjFromUrlOrParams,
    (state) => state.studyProgrammeApiData.allPrograms,
  ],
  (
    curriculumKey,
    edPointers,
    educationalActivityTypes,
    extractedCurriculum,
    layeredAnnotationsObjResult,
    allPrograms
  ) => {
    const endLogTime = conditionalLogTime('selectAnnotatedCurriculumGoalListData', 2);
    const { layeredAnnotationsObj, referencedItems } = layeredAnnotationsObjResult;
    let extractedCurriculumNodes = extractedCurriculum;

    if (curriculumKey === 'nonderived') {
      extractedCurriculumNodes = flattenAndExtractCurriculaItems(getCustomGoalList());
    }

    if (extractedCurriculumNodes) {
      if (referencedItems) {
        Object.values(referencedItems).forEach((item) => {
          const itemWithDraggable = addDraggable(item);
          const extract = flattenAndExtractCurriculaItems(itemWithDraggable);
          extractedCurriculumNodes = {
            ...extractedCurriculumNodes,
            ...extract,
          };
        });
      }

      extractedCurriculumNodes = excludeMandatoryResources(
        buildGoalListItems(extractedCurriculumNodes, edPointers, educationalActivityTypes)
      );

      if (layeredAnnotationsObj) {
        extractedCurriculumNodes = buildAnnotations(
          layeredAnnotationsObj,
          extractedCurriculumNodes,
          allPrograms
        );
      }
    }

    endLogTime();
    return extractedCurriculumNodes;
  },
  {
    memoizeOptions: {
      // equalityCheck: (previousVal, currentVal) => {
      //   const rv = currentVal === previousVal;
      //   if (!rv) console.log('Selector param value changed', currentVal);
      //   return rv;
      // },
      resultEqualityCheck: isEqual,
      // resultEqualityCheck: deepEqualWithLog('selectAnnotatedCurriculumGoalListData'),
    },
  }
);

export const selectCurriculumGoalHrefs = createSelector(
  [selectAnnotatedCurriculumGoalListData],
  (extractedCurriculumNodes) => {
    if (!extractedCurriculumNodes) return extractedCurriculumNodes;
    return Object.values(extractedCurriculumNodes)
      .filter((z) => z.type === CONTENTTYPES.goal || z.type === CUSTOMTYPES.goal)
      .map((e) => e.href);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

const selectGoalListItems = createSelector(
  [selectAnnotatedCurriculumGoalListData],
  (extractedCurriculumNodes) => {
    if (!extractedCurriculumNodes) return extractedCurriculumNodes;
    const endLogTime = conditionalLogTime('selectAnnotatedGoalFromCurriculumViewModel', 2);
    const goalList = extractedCurriculumNodes && buildGoalListTab(extractedCurriculumNodes);
    const items = getViewModelEntities(goalList);
    endLogTime();

    return items;
  }
);

export const selectGoalsFromCurriculum = createSelector([selectGoalListItems], (goalList) => {
  const goals = goalList?.filter(
    (goal) => goal.type === CONTENTTYPES.goal || goal.type === CUSTOMTYPES.goal
  );
  return goals;
});

export const selectGoalsAndSectionAndGoalListFromCurriculum = createSelector(
  [(state, params) => selectGoalListItems(state, params)],
  (goalList) => {
    const goals = goalList?.filter(
      (item) =>
        item.type === CONTENTTYPES.goal ||
        item.type === CUSTOMTYPES.goal ||
        item.type === CONTENTTYPES.goalSection ||
        item.type === CUSTOMTYPES.section ||
        item.type === CUSTOMTYPES.goalList ||
        item.type === CONTENTTYPES.goalList
    );
    return goals;
  }
);

export const selectCanEditCustomCur = createSelector(
  [selectBossOrgHrefs, selectCustomCurriculaFromUrlOrParams],
  (bossOrgHrefs, customCurriculaFromUrl) => {
    if (!customCurriculaFromUrl || !customCurriculaFromUrl.length) return false;
    return bossOrgHrefs.includes(customCurriculaFromUrl[0].creator.href);
  }
);

export const selectHideOptionalElements = createSelector(
  [
    (state) => state.userAndSchools.privateState?.state?.hideOptionalElements,
    (state) => state.curriculum.setid,
    (state) => state.curriculum.custids,
    (state) => state.curriculum.curriculumKey,
  ],
  (hideOptionalElements, setid, custids, curriculumKey) => {
    if (!hideOptionalElements) return false;
    const hideOptionalElementsForCur = hideOptionalElements.includes(
      setid || custids?.[0] || curriculumKey
    );
    return hideOptionalElementsForCur;
  }
);

export const selectFullAnnotatedCurriculumViewModel = createSelector(
  [
    // () => {
    //   console.time('selectFullAnnotatedCurriculumViewModelSelectors');
    //   return null;
    // },
    (state, params) => getCurriculumKeyFromStateOrParams(state, params),
    (state, params) =>
      getCurriculumKeyFromStateOrParams(state, params) !== 'nonderived'
        ? selectBaseCurriculumViewModel(state, params)
        : selectBaseNonDerivedCurriculumViewModel(state, params),
    selectAnnotatedCurriculumGoalListData,
    selectCustomCurriculaFromUrlOrParams,
    // (state) => state.curriculum.studids,
    selectCurrentSchoolyear,
    selectLayeredAnnotationsObjFromUrlOrParams,
    (state) => state.studyProgrammeApiData.allPrograms,
    selectLeidraadTop5AndEduPointers,
    (state, params) => selectAllCreatorsActiveInSchoolyear(state, params),
    (state) =>
      state.userAndSchools.privateState?.state?.collapseSections?.[selectCurrentSchoolHref(state)],
    selectCanEditCustomCur,
    selectHideOptionalElements,
    (state) => state.distribute.distributionMode,
    // () => {
    //   console.timeEnd('selectFullAnnotatedCurriculumViewModelSelectors');
    //   return null;
    // },
  ],
  (
    // ignore,
    curriculumKey,
    baseCurrVM,
    extractedCurriculumNodes,
    customCurricula,
    currentSchoolyear,
    layeredAnnotationsObjResult,
    allPrograms,
    schoolChoices,
    allOrgs,
    collapseSections,
    canEditCustomCur,
    hideOptionalElements,
    isDistributionMode
  ) => {
    if (!baseCurrVM) return baseCurrVM;

    const endLogTime = conditionalLogTime('selectFullAnnotatedCurriculumViewModel', 2);

    const origViewModel = baseCurrVM;
    const { layeredAnnotationsObj } = layeredAnnotationsObjResult;

    let modifiedCurriculumNodes = extractedCurriculumNodes;
    const titles =
      layeredAnnotationsObj &&
      customCurricula &&
      customCurricula.length &&
      getTitleObjects(
        origViewModel.title,
        layeredAnnotationsObj[Object.keys(layeredAnnotationsObj)[0]],
        customCurricula.length,
        allPrograms,
        allOrgs,
        origViewModel.grade
      );

    const { expired } = currentSchoolyear;
    const readonly = !canEditCustomCur;

    const collapsedSectionsForCur = collapseSections?.[curriculumKey];
    if (hideOptionalElements) {
      modifiedCurriculumNodes = triggerSelectedItems(modifiedCurriculumNodes);
    }
    modifiedCurriculumNodes = setCollapsedSections(
      modifiedCurriculumNodes,
      collapsedSectionsForCur
    );

    if (isDistributionMode) {
      modifiedCurriculumNodes = modifyGoalsForDistribution(modifiedCurriculumNodes);
    }

    const viewModel = {
      ...origViewModel,
      schoolLevel: schoolChoices,
      ...titles,
      expired,
      readonly,
      doelenlijst: {
        ...buildGoalListTab(modifiedCurriculumNodes),
      },
    };

    endLogTime();
    // return deepFreeze(viewModel);
    return viewModel;
  }
  // {
  //   memoizeOptions: {
  //     // resultEqualityCheck: shallowEqual,
  //     resultEqualityCheck: deepEqualWithLog('selectFullAnnotatedCurriculumViewModel'),
  //     // resultEqualityCheck: shallowEqualWithLog('selectFullAnnotatedCurriculumViewModel'),
  //   },
  // }
);

export const selectParentTreeMap = createSelector(
  [selectFullAnnotatedCurriculumViewModel],
  (vm) => {
    const vmmap = new Map();

    const mapChildren = (child, parent) => {
      const parentNode = new CurriculumNode(child, parent);
      vmmap.set(child.key, parentNode);

      child.goals?.forEach((g) => mapChildren(g, parentNode));
      child.sections?.forEach((s) => mapChildren(s, parentNode));
      child.subItems?.forEach((p) => mapChildren(p, parentNode));
    };

    mapChildren(vm.doelenlijst, null);
    return vmmap;
  }
);

export const selectIsGoalSelected = createSelector(
  [(state) => state.curriculum.selectedItems, (state, params) => params.key],
  (selectedGoals, goalKey) => {
    return selectedGoals?.find((g) => getKeyFromHref(g.href) === goalKey) ?? false;
  }
);

export const selectCurriculaReadOnly = createSelector(
  [selectCanEditCustomCur, selectIsSchoolyearReadOnly],
  (canEdit, schoolyearReadOnly) => {
    return !canEdit || schoolyearReadOnly;
  }
);

export const selectCurriculumIdentifier = createSelector(
  [selectFullAnnotatedCurriculumViewModel],
  (fullAnnotatedCurriculumViewModel) => {
    if (!fullAnnotatedCurriculumViewModel) return undefined;
    return fullAnnotatedCurriculumViewModel.identifiers?.[0];
  }
);

export const selectSelectedGoalsForPostmessage = (state) => {
  const { selectedItems } = state.curriculum;
  if (!selectedItems) return [];
  const allGoals = selectAllGoals(state);

  const selectedGoals = selectedItems.map((z) => allGoals[getKeyFromHref(z.href)] || z);

  return selectedGoals.map((i) => ({
    href: i.href,
    baseCurriculum: i.rootKey ? `/content/${i.rootKey}` : null,
  }));
};

/**
 * this selector expects the EditActivityModal to calculate and set the baseGoalOccurence into the state.
 * there is no way to repeatedly calculate the baseGoalOccurence without the EditActivityModal's state being in redux.
 * @param {*} state
 * @param {*} param1
 * @returns
 */
export const selectGoalCount = (state, { key }) => {
  const { selectedItems, selectionMode, baseGoalOccurence } = state.curriculum;
  const href = `/content/${key}`;
  if (selectionMode && baseGoalOccurence?.[href] !== undefined) {
    const isItemSelected = selectedItems?.some((z) => z.href === href);
    const count = isItemSelected ? baseGoalOccurence[href] + 1 : baseGoalOccurence[href];
    return count || null; // only return if count is not 0 (avoid react print 0)
  }
  return null;
};

export const selectCurriculumTabs = createSelector(
  [
    (state) =>
      state.curriculum.curriculumKey !== 'nonderived'
        ? selectBaseCurriculumViewModel(state)
        : selectBaseNonDerivedCurriculumViewModel(state),
    (state) => Boolean(state.curriculum.custids?.length || state.curriculum.setid),
    (state) => state.distribute.distributionMode,
    (state) => state.curriculum.selectionMode,
  ],
  (baseCurriculum, isCustomCur, isDistributionMode, isSelectionMode) => {
    if (!baseCurriculum || isSelectionMode) return undefined;
    const curriculumTabs = [];

    let doelenlijstLabel = TABNAMES.doelenlijst;
    if (isCustomCur) {
      doelenlijstLabel = TABNAMES.customplan;
    }
    if (isDistributionMode) {
      doelenlijstLabel = TABNAMES.distributie;
    }

    Object.keys(CONTENTTYPES.tabs).forEach((key) => {
      if (
        (baseCurriculum[key] &&
          Object.keys(baseCurriculum[key]).length &&
          baseCurriculum[key].items?.length) || // check if the tab has content
        key === 'doelenlijst'
      ) {
        let routePart = `${key}`;
        if (key === 'doelenlijst' && isDistributionMode) {
          routePart = 'distributie'; // distribution has its own route, but it uses the doelenlijst button
        }
        curriculumTabs.push({
          key,
          label: key === 'doelenlijst' ? doelenlijstLabel : TABNAMES[key],
          route: routePart,
        });
      }
    });

    return curriculumTabs;
  }
);
