const formatPlanAPI = (plan) => {
  return {
    $$meta: plan.$$meta,
    key: plan.key,
    title: plan.title,
    issued: plan.issued,
    class: plan.class,
  };
};

const formatCurriculumAPI = (curriculum) => ({
  href: curriculum.curriculumHref,
});

const formatCreatorAPI = (teacher) => ({ href: teacher.$$meta.permalink });

const formatCalendarAPI = ({
  classes,
  curriculum,
  school,
  schoolyear,
  shared,
  teachers,
  title,
}) => {
  return {
    title,
    curriculum: curriculum?.map(formatCurriculumAPI),
    school: { href: school.href },
    schoolyear: schoolyear.isoDates,
    shared: shared?.map(formatCreatorAPI),
    teachers: teachers?.map(formatCreatorAPI),
    classes,
  };
};

const formatCalendarCloneAPI = ({
  originalCalendar,
  missingGoals,
  classes,
  curriculum,
  schoolyear,
  shared,
  school,
  teachers,
  title,
}) => {
  const simplePlans = originalCalendar.plans?.map((p) => {
    const plan = formatPlanAPI(p);
    return { ...plan, activities: p.activities };
  });

  const cloneCalendar = formatCalendarAPI({
    classes,
    curriculum,
    school,
    schoolyear,
    shared,
    teachers,
    title,
  });
  cloneCalendar.classes = Object.values(classes)
    ?.flat()
    .filter((e) => e);

  return {
    originalCalendar: { ...originalCalendar, plans: simplePlans },
    goalTranslationsMap: missingGoals?.goalTranslationsMap,
    classes,
    cloneCalendar,
    schoolyear: schoolyear.key,
  };
};

const formatCalendarCreationAPI = ({
  classes,
  curriculum,
  school,
  schoolyear,
  shared,
  teachers,
  title,
  missingGoals,
}) => {
  const calendar = formatCalendarAPI({
    classes,
    curriculum,
    school,
    schoolyear,
    shared,
    teachers,
    title,
  });

  return { ...calendar, goalTranslationsMap: missingGoals?.goalTranslationsMap };
};

export default {
  formatCalendarCloneAPI,
  formatCalendarCreationAPI,
  formatPlanAPI,
};
